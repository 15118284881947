<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea v3">
			<div class="inner">
				<div class="location">
					<span @click="onBuilding(building.id)" style="cursor: pointer;">{{ building.name }}</span>
					<span>{{ floorName }} </span>
				</div>
				<div class="slideArea">
					<p class="page">
						<strong>{{ currentFloors }}</strong
						>/{{ totalFloors }}
					</p>
					<div class="btnBox">
						<button type="button" class="btnPrev" :disabled="1 === currentFloors" @click="onPrevFloor">이전</button>
						<button
							type="button"
							class="btnNext"
							:disabled="building.floors.length === currentFloors"
							@click="onNextFloor"
						>
							다음
						</button>
					</div>
				</div>
			</div>
		</div>
		<!--// titArea -->
		<!-- tabWrap -->
		<div class="tabWrap">
			<ul>
				<li v-for="(item, idx) in tabInfo" :class="{ curr: idx == tabId, icoInfo: !data[idx] }" :key="idx">
					<a href="javascript:void(0)" @click="() => (tabId = idx)">{{ item.name }}</a>
				</li>
			</ul>
			<div class="tabBox">
				<div :class="{ boxWhite: true }" v-for="(item, idx) in tabInfo" v-show="idx == tabId" :key="`plan-${idx}`">
					<div class="inner">
						<div class="boxLine" v-if="data[idx]">
							<div ref="floorPlanArea" class="floorPlanArea">
								<div class="viewArea">
									<img
										:ref="'imgPlan' + idx"
										:src="'/file/' + data[idx].imageId"
										:style="{
											position: 'absolute',
											top: `${data[idx].offsetY}px`,
											left: `${data[idx].offsetX}px`,
											width: `${data[idx].orgWidth * data[idx].scale}px`,
											height: `${data[idx].orgHeight * data[idx].scale}px`,
										}"
										@load="onLoadImgPlan(idx)"
									/>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										version="1.2"
										baseProfile="tiny"
										class="svg"
										:ref="'svgViewer' + idx"
										width="100%"
										height="100%"
										style="left:0; top:0;"
										@mousedown.capture="onMousedown"
										@mousemove.capture="onMousemove"
										@mouseup.capture="onMouseup"
										@mouseleave="onMouseleave"
										@touchstart="onTouchstart"
										@touchmove="onTouchmove"
										@touchend="onTouchend"
									>
										<g v-for="(room, idxRoom) in data[idx].rooms" :key="`room-${idxRoom}`">
											<rect
												v-if="'rect' === room.type"
												style="pointer-events: none;"
												:x="room.x * data[idx].scale + data[idx].offsetX"
												:y="room.y * data[idx].scale + data[idx].offsetY"
												:width="room.width * data[idx].scale"
												:height="room.height * data[idx].scale"
												rx="8"
												:fill="style.fillColor"
												opacity="0.2"
											/>
											<circle
												v-if="'circle' === room.type"
												style="pointer-events: none;"
												:cx="room.cx * data[idx].scale + data[idx].offsetX"
												:cy="room.cy * data[idx].scale + data[idx].offsetY"
												:r="room.r * data[idx].scale"
												:fill="style.fillColor"
												opacity="0.2"
											/>
											<polygon
												v-if="'polygon' === room.type"
												style="pointer-events: none;"
												:points="
													room.points
														.map((it, i) => it * data[idx].scale + (i % 2 ? data[idx].offsetY : data[idx].offsetX))
														.join(' ')
												"
												:fill="style.fillColor"
												opacity="0.2"
											/>
											<g
												v-for="(pin, idxPin) in room.objectpins"
												:key="`pin-${idxPin}`"
												style="cursor: pointer;"
												:transform="
													`translate(${pin.x * data[idx].scale + data[idx].offsetX - 5}, ${pin.y * data[idx].scale +
														data[idx].offsetY -
														24})`
												"
												@click="onSelectPin(pin, $event)"
												@touchstart="onSelectPin(pin, $event)"
											>
												<rect width="18" height="26" fill="rgba(0,0,0,0)" />
												<path
													d="M12.791,12.893,15.375,4.95a3.38,3.38,0,0,0,2.377-1.734L9.878,0a3.4,3.4,0,0,0,.469,2.925l-3.7,7.458A9.32,9.32,0,0,0,0,12.955L7.479,16.01,4.12,24.234l.778.318,3.359-8.224,7.479,3.054a9.32,9.32,0,0,0-2.945-6.489"
													:fill="style.roomStroke"
												/>
											</g>
											<a
												style="z-index:100;"
												href="javascript:void(0)"
												@click="OnSelectRoom(room.id)"
												@touchstart="OnSelectRoom(room.id)"
												v-if="~['rect', 'circle', 'polygon'].indexOf(room.type)"
											>
												<rect
													:x="
														room.centerX * data[idx].scale -
															lenOfStr(roomName(room, idx)) * 0.5 -
															10 +
															data[idx].offsetX
													"
													:y="room.centerY * data[idx].scale - 15 + data[idx].offsetY"
													:width="lenOfStr(roomName(room, idx)) + 20"
													height="28"
													rx="14"
													fill="#0C234C"
												/>
												<text
													:x="room.centerX * data[idx].scale + data[idx].offsetX"
													:y="room.centerY * data[idx].scale + data[idx].offsetY"
													font-size="14px"
													text-anchor="middle"
													dominant-baseline="middle"
													style="fill:#fff"
												>
													{{ roomName(room, idx) }}
												</text>
											</a>
										</g>
									</svg>
								</div>
								<!-- btnZoomArea -->
								<div class="btnZoomArea">
									<div class="inBox">
										<a href="javascript:void(0)" @click="onZoomIn()">확대</a>
										<a href="javascript:void(0)" @click="onZoomOut()">축소</a>
									</div>
								</div>
								<!--// btnZoomArea -->
								<a v-if="auth" href="javascript:void(0)" class="btn btnWhite ss" @click="onSetObjectPin()"
									>오브제핀설정</a
								>
							</div>
							<a
								v-if="auth"
								style="position:absolute; z-index:99;"
								href="javascript:void(0)"
								class="btnSet"
								@click="onEditPlan(idx)"
							>
								설정
							</a>
						</div>
						<div v-else class="buildList">
							<ul>
								<li>
									<a v-if="auth" href="javascript:void(0)" class="btnAdd" @click="onAddPlan(idx)">
										<div class="imgArea"><img src="/resources/images/ico-addline.svg" alt="" /></div>
										<span>도면등록</span>
									</a>
									<div v-else></div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--// tabWrap -->
		<!-- contents -->
		<div class="contents">
			<!-- memoList -->
			<div class="memoList">
				<ul v-if="memo.total > 0">
					<li v-for="(memo, index) in memoList" :key="index">
						<a @click="onMemo(memo.id)">
							<!-- location -->
							<div class="location">
								<span>{{ memo.buildingName }}</span>
								<span v-if="memo.floorId != null">{{ memo.floorName }}</span>
								<span v-if="memo.roomId != null">{{ memo.roomName }}</span>
								<span v-if="memo.objectPinId != null">{{ memo.objectName }}</span>
								<span v-if="memo.locations && memo.locations.length > 1" class="locationCount"
									>외 {{ memo.locations.length - 1 }}개</span
								>
							</div>
							<!--// location -->
							<!-- memo -->
							<div class="memo">
								<div class="user">
									<div class="imgArea">
										<p><img :src="`/file/${memo.creatorImageId}`" alt="" v-if="memo.creatorImageId" /></p>
									</div>
									<p>{{ memo.creatorName }}</p>
								</div>
								<div class="user" v-if="memo.projectName != null">
									<span> <strong>[프로젝트]</strong> </span>
									<span class="title">&nbsp;{{ memo.projectName }}</span>
									<span class="interval">
										&nbsp;({{ memo.projectBeginDate | dateFormatYear }}~{{ memo.projectEndDate | dateFormatYear }})
									</span>
								</div>
								<div class="user" v-if="memo.schedule.name">
									<span>
										<strong>[스케줄]</strong>
									</span>
									<span class="title">&nbsp;{{ memo.schedule.name }}</span>
									<span class="interval"
										>&nbsp;({{ setIntervalStr(memo.schedule.intervalType) }} {{ setDateFormat(memo.schedule) }})</span
									>
								</div>
								<p class="date">
									<span>{{ memo.createDate | dateFormatYear }}</span>
									<span>{{ memo.createDate | dateFormatMonth }}</span>
								</p>
								<p>
									<span>
										<pre v-if="memo.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
											enterContent(memo.content).replace(/ /g, '&nbsp;')
										}}</pre>
									</span>
								</p>
							</div>
							<!--// memo -->
						</a>
						<!-- imgList -->
						<div class="devImgList">
							<div class="inBox">
								<p v-for="(memoFile, index) in memo.memoFiles" :key="index">
									<img :src="`/file/${memoFile.fileId}`" alt="" @click="onOpenImage(memoFile.fileId, memo.memoFiles)" />
								</p>
							</div>
						</div>
						<!--// imgList -->
						<!-- viewInfo -->
						<div class="viewInfo">
							<a class="btnComment">{{ memo.commentCount }}</a>
							<a class="btnViews">{{ memo.views }}</a>
						</div>
						<!--// viewInfo -->
					</li>
				</ul>
				<ul v-else>
					<h3>최신글</h3>
					<!-- noData -->
					<div class="noData">
						<p>등록된 최신글이 없습니다.</p>
					</div>
				</ul>
			</div>
			<!--// memoList -->
			<!-- btnFixed -->
			<div class="btnFriArea" v-if="friendAuth == true">
				<div class="inBox">
					<a @click="memoRegist" class="btnEdit">메모하기</a>
				</div>
			</div>
			<!--// btnFixed -->
		</div>
		<!--// contents -->
		<PopupFile ref="popupFile"></PopupFile>
		<PopUpImage ref="popUpImage"></PopUpImage>
		<PopupObjectPinInfo ref="popupObjectPinInfo" @createMemo="onRegistObjectPinMemo"></PopupObjectPinInfo>
		<span id="strWidth" ref="strWidth" style="visibility:hidden; position:absolute; top:-10000;"></span>
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner } from '@/utils/cmm';
import { dateFormatYear, dateFormatMonth, getAuth, sizeOfImage, setIntervalStr } from '@/utils/cmm';
import building from '@/api/building';
import floor from '@/api/floor';
import floorplan from '@/api/floorplan';
import room from '@/api/room';
import objectpin from '@/api/objectpin';
import friend from '@/api/friend';
import memo from '@/api/memo';
import PopupFile from './popupFile';
import PopUpImage from '../popup/popUpImage';
import PopupObjectPinInfo from './popupObjectPinInfo';
export default {
	// eslint-disable-next-line vue/no-unused-components
	components: { PopupFile, PopUpImage, PopupObjectPinInfo },

	data() {
		return {
			floorId: Number(this.$route.params.id),
			tabId: 0,
			tabInfo: [
				{ tabId: 0, code: 'FRPLBASC', name: '기본평면도' },
				{ tabId: 1, code: 'FRPLELEC', name: '전기도면' },
				{ tabId: 2, code: 'FRPLFIRE', name: '소방도면' },
				{ tabId: 3, code: 'FRPLHYPL', name: '위생/배관' },
			],
			tabIndex: {}, // tabInfo에서 자동 생성됨.
			building: { floors: [] },
			floors: 0,
			popupFile: false,
			data: [],
			owner: 0,
			memoList: [],
			memo: {
				offset: 0,
				limit: 10,
				total: '',
			},
			auth: false,
			friendAuth: false,
			style: {
				fillColor: '#0C234C',
				roomStroke: '#0c234c',
			},
			isDrag: false,
			pointer: null,
		};
	},
	computed: {
		floorName() {
			const floors = this.building && this.building.floors;
			if (!floors) return '';
			const floor = floors[this.currentFloors - 1];
			if (!floor || !floor.floors) return '';
			return 0 < floor.floors ? (floor.floors != 999 ? `지상${floor.floors}층` : '옥상') : `지하${-floor.floors}층`;
		},
		totalFloors() {
			const building = this.building;
			return building.floors.length;
		},
		currentFloors() {
			const res = this.building.floors.findIndex(it => it.id === this.floorId);
			return ~res ? res + 1 : '';
		},
		planMetaInfo() {
			return this.tabInfo[this.tabId];
		},
	},
	watch: {
		floorId() {
			this.reload();
		},
		tabId(newVal) {
			this.$nextTick(() => this.onLoadImgPlan(newVal));
		},
	},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	created() {
		window.addEventListener('resize', this.onResize);
		this.tabInfo.forEach(it => (this.tabIndex[it.code] = it));
		this.init();
	},
	mounted() {
		this.$EventBus.$on('paging', () => {
			if (this.memo.total <= this.memo.offset) return;
			this.onGetMemo();
		});
	},
	destroyed() {
		this.$EventBus.$off('paging');
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.onLoadImgPlan(this.tabId);
		},
		onEditPlan(tabId) {
			let floorPlan = this.data[tabId];
			// console.log('floorPlan edit click');
			// console.log(floorPlan);

			this.$router.push({
				name: 'floorPlanEdit',
				params: { id: floorPlan.id, fId: floorPlan.floorId },
			});
		},
		async onAddPlan() {
			let res = await this.$refs.popupFile.select(this.floors);
			// console.log('onAddPlan res:');
			// console.log(res);
			if (!res) return;
			try {
				showSpinner();
				// console.log('floorId:');
				// console.log(this.floorId);
				// console.log('this.planMetaInfo.code', this.planMetaInfo.code);
				// console.log('res.value.id', res.value.id);

				if ('file' === res.type) {
					await floorplan.create({ floorId: this.floorId, type: this.planMetaInfo.code, imageId: res.value.id });
				} else if ('copy' === res.type) {
					await floor.copyUnder({ id: this.floorId });
				}
				this.reload();
			} catch (ex) {
				await this.$alert(`도면등록 에러가 발생했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		onPrevFloor() {
			this.floorId = this.building.floors[this.currentFloors - 2].id;
		},
		onNextFloor() {
			this.floorId = this.building.floors[this.currentFloors].id;
		},
		async OnSelectRoom(id) {
			// console.log('id');
			// console.log(id);
			// this.$router.push({ name: 'roomDetail', params: { id: id, fin: floorplan } });
			this.$router.push({ name: 'roomDetail', params: { id } });
		},
		async init() {
			try {
				showSpinner();
				let res = await floor.getOne(this.floorId);
				const buildingId = res.buildingId;
				let resBuilding = await building.getOne(buildingId);
				this.owner = resBuilding.owner;
				let floors = await floor.get({ buildingId });
				resBuilding.floors = floors.data.sort((l, r) => r.floors - l.floors);
				this.building = resBuilding;
				this.floors = res.floors;
				await this.friendInfo();
			} catch (ex) {
				return await this.$alert(`층 상세 정보 조회 에러가 발생했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}

			await this.reload();
		},
		async reload() {
			try {
				// console.log('reload fn:');
				showSpinner();
				// console.log('floorplan get with:', this.floorId);
				let res = await floorplan.get({ floorId: this.floorId });
				res = res.data;
				// let roomIds = [];
				let roomIds = {};
				// console.log('floorplan get res output');
				// console.log(res);
				let data = [];
				if (res) {
					for (let it of res) {
						try {
							const size = await sizeOfImage(`/file/${it.imageId}`);
							it.orgWidth = size.width;
							it.orgHeight = size.height;
							it.scale = 1;
							it.offsetX = 0;
							it.offsetY = 0;
							it.loaded = false;
							let rooms = await room.get({ floorPlanId: it.id });

							// console.log('rooms');
							// console.log(rooms);
							roomIds[it.id] = [];

							if (rooms.total === 0) {
								let createdRoom = await room.create({
									floorPlanId: it.id,
									name: '...',
									shapeType: 'all',
									shapeInfo: '',
								});
								roomIds[it.id].push(createdRoom.id);
							}
							it.rooms = rooms.data || [];
							it.rooms = it.rooms
								.filter(room => room.shapeType)
								.map(room => {
									roomIds[it.id].push(room.id);
									// roomIds.push(room.id);
									const vals = room.shapeInfo.split(' ').map(val => Number(val));
									let ret = { id: room.id, type: room.shapeType, name: room.name };
									switch (room.shapeType) {
										case 'rect':
											Object.assign(ret, {
												x: vals[0],
												y: vals[1],
												width: vals[2],
												height: vals[3],
												centerX: vals[0] + vals[2] / 2,
												centerY: vals[1] + vals[3] / 2,
											});
											break;
										case 'circle':
											Object.assign(ret, {
												cx: vals[0],
												cy: vals[1],
												r: vals[2],
												width: vals[2] * 2,
												height: vals[2] * 2,
												centerX: vals[0],
												centerY: vals[1],
											});
											break;
										case 'polygon':
											{
												let width = [vals[0], vals[0]];
												let height = [vals[1], vals[1]];
												for (let i = 2; i < vals.length; i += 2) {
													width[0] = Math.min(width[0], vals[i]);
													width[1] = Math.max(width[1], vals[i]);
													height[0] = Math.min(height[0], vals[i + 1]);
													height[1] = Math.max(height[1], vals[i + 1]);
												}
												Object.assign(ret, {
													points: vals,
													width: width[1] - width[0],
													height: height[1] - height[0],
													centerX: (width[0] + width[1]) * 0.5,
													centerY: (height[0] + height[1]) * 0.5,
												});
											}
											break;
										case 'all':
											it.allRoomId = room.id;
											break;
									}

									objectpin.get({ roomId: room.id }).then(res => {
										if (!res.total) return;
										// console.log('object pin data');
										// console.log(res.data);
										ret.objectpins = res.data
											.filter(pin => pin.isUse)
											.map(pin => {
												const pinArea = pin.areaInfo.split(' ').map(val => Number(val));
												return {
													id: pin.id,
													name: pin.objectName,
													roomId: room.id,
													x: pinArea[0] + pinArea[2] * 0.5,
													y: pinArea[1] + pinArea[3] * 0.5,
												};
											});
									});

									return ret;
								});
						} catch (ex) {
							console.error(ex);
						}
						const tabId = this.tabIndex[it.type].tabId;
						data[tabId] = it;
					}
				}
				// console.log('Room IDs:', roomIds);
				this.roomIds = roomIds;
				this.data = data;

				// console.log('this data');
				// console.log(this.data);

				this.memo.offset = 0;
				this.memoList = [];
				await this.onGetMemo();
				await this.friendInfo();

				// const temp_data = data[this.tabId];
				// try {
				// 	if (temp_data && !temp_data.allRoomId) {
				// 		// console.log('here1');
				// 		let res = await room.get({ floorPlanId: temp_data.id, shapeType: 'all' });
				// 		if (res.total) {
				// 			// console.log('here2');
				// 			temp_data.allRoomId = res.data[0].id;
				// 		} else {
				// 			// console.log('here3');
				// 			res = await room.create({ floorPlanId: temp_data.id, name: '...', shapeType: 'all', shapeInfo: '' });
				// 			temp_data.allRoomId = res.id;
				// 		}
				// 		this.data[this.tabId] = temp_data;
				// 	}
				// } catch (ex) {
				// 	return await this.$alert(`오브제핀설정 실패했습니다.\r\n${ex.message}`);
				// }

				// console.log('this.data final');
				// console.log(this.data);
				// console.log('this.roomIds');
				// console.log(this.roomIds);
			} catch (ex) {
				await this.$alert(`도면조회 에러가 발생했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onGetMemo() {
			let params = {
				offset: this.memo.offset,
				limit: this.memo.limit,
				buildingId: this.building.id,
				floorId: this.floorId,
			};
			try {
				let res = await memo.get(params);
				this.memo.total = res.total;
				if (this.memo.total > 0) {
					res.data.forEach(p => this.memoList.push(p));
					this.memo.offset += this.memo.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		async friendInfo() {
			let res = await building.getOne(this.building.id);
			if (getAuth().id != res.owner) {
				let params = {
					buildingId: this.building.id,
					userId: res.owner,
					friendId: getAuth().id,
					state: 'F',
				};
				let res2 = await friend.getOne(params);
				if (res2.authority == 'A') this.friendAuth = true;
			} else {
				this.auth = true;
				this.friendAuth = true;
			}
		},
		onMemo(id) {
			this.$router.push({ name: 'memoDetail', params: { id: id, reqUrl: 'floor' } });
		},
		onLoadImgPlan(idx) {
			const data = this.data[idx];
			if (!data || data.loaded) return;

			const svgViewer = this.$refs['svgViewer' + idx];
			if (!svgViewer || 1 !== svgViewer.length) return;

			const { width, height } = svgViewer[0].getBoundingClientRect();
			const scale = Math.min(width / data.orgWidth, height / data.orgHeight);
			if (!scale) return;

			data.scale = scale;
			data.offsetX = (width - data.orgWidth * data.scale) * 0.5;
			data.offsetY = (height - data.orgHeight * data.scale) * 0.5;
			data.loaded = true;
		},
		memoRegist() {
			this.$router.push({ name: 'memoRegist', params: { buildingId: this.building.id, floorId: this.floorId } });
		},
		onRegistObjectPinMemo({ roomId, id: objectPinId }) {
			this.$router.push({
				name: 'memoRegist',
				params: { reqUrl: 'floor', buildingId: this.building.id, floorId: this.floorId, roomId, objectPinId },
			});
		},
		onBuilding(buildingId) {
			this.$router.push({ name: 'buildingDetail', params: { id: buildingId } });
		},
		onMousedown(ev) {
			const pointer = { x: ev.offsetX, y: ev.offsetY };
			this.isDrag = true;
			this.pointer = pointer;
			ev.preventDefault();
		},
		onMousemove({ offsetX, offsetY }) {
			if (!this.isDrag) return;

			const pointer = { x: offsetX, y: offsetY };
			const data = this.data[this.tabId];
			const dx = pointer.x - this.pointer.x;
			const dy = pointer.y - this.pointer.y;
			data.offsetX += dx;
			data.offsetY += dy;

			this.pointer = pointer;
		},
		async onMouseup() {
			this.isDrag = false;
		},
		onMouseleave() {
			this.isDrag = false;
		},
		async onSetObjectPin() {
			// console.log('this.data');
			// console.log(this.data);
			// console.log('this.roomIds');
			// console.log(this.roomIds);
			const data = this.data[this.tabId];
			// console.log('click object pin roomIds');
			// console.log(data);

			// console.log('this.roomIds');
			// console.log(this.roomIds);

			try {
				if (!data.allRoomId) {
					// console.log('here1');
					let res = await room.get({ floorPlanId: data.id, shapeType: 'all' });
					if (res.total) {
						// console.log('here2');
						data.allRoomId = res.data[0].id;
					} else {
						// console.log('here3');
						res = await room.create({ floorPlanId: data.id, name: '...', shapeType: 'all', shapeInfo: '' });
						data.allRoomId = res.id;
					}
				}
			} catch (ex) {
				return await this.$alert(`오브제핀설정 실패했습니다.\r\n${ex.message}`);
			}
			// console.log('onSetObjectPin page here');
			// console.log('onSetObjectPin page open');
			// console.log(data);
			// console.log('this.roomIds .roomIds');
			// console.log(this.roomIds);
			// console.log('onSetObjectPin data');

			// console.log(data);

			// console.log(this.roomIds[data.id]);
			// this.$router.replace({ name: 'floorObjectPin', params: { id: data.allRoomId,  } });

			// // if (this.roomIds.length === 0) {
			// if (!this.roomIds[data.id] || this.roomIds[data.id].length === 0) {
			// 	//in case territory not exist...')
			// 	await this.$alert('Please create a territory');
			// 	// return;
			// 	// this.$router.go(-1);
			// 	let floorPlan = data;
			// 	// console.log('floorPlan edit click');
			// 	// console.log(floorPlan);
			// 	this.$router.push({
			// 		name: 'floorPlanEdit',
			// 		params: { id: floorPlan.id, fId: floorPlan.floorId },
			// 	});
			// } else {
			// 	//If roomIds is not empty, navigate to floorObjectPin route
			// 	this.$router.replace({
			// 		name: 'floorObjectPin',
			// 		params: {
			// 			id: this.roomIds,
			// 			floorPlanId: data.id,
			// 			imageId: data.imageId,
			// 		},
			// 	});
			// }

			this.$router.replace({
				name: 'floorObjectPin',
				params: {
					id: this.roomIds,
					floorPlanId: data.id,
					imageId: data.imageId,
				},
			});
		},
		onTouchstart(ev) {
			ev.preventDefault();

			const { target, touches } = ev;
			const { clientX, clientY } = touches[0];

			target.dispatchEvent(new MouseEvent('mousedown', { clientX, clientY }));
		},
		onTouchmove(ev) {
			const { target, touches } = ev;
			const { clientX, clientY } = touches[0];

			target.dispatchEvent(new MouseEvent('mousemove', { clientX, clientY }));
		},
		onTouchend({ target }) {
			this.onMouseup({ target });
		},
		onZoomIn() {
			this.zoom(0.1);
		},
		onZoomOut() {
			this.zoom(-0.1);
		},
		onOpenImage(imageId, memoFiles) {
			this.$refs.popUpImage.onOpenImage(imageId, memoFiles);
		},
		onSelectPin(pin) {
			this.$refs.popupObjectPinInfo.select(pin);
		},
		zoom(scale) {
			const data = this.data[this.tabId];
			data.scale += scale;
			const svgViewer = this.$refs['svgViewer' + this.tabId];
			if (!svgViewer || 1 !== svgViewer.length) return;
			const { width, height } = svgViewer[0].getBoundingClientRect();
			data.offsetX -= width * scale * 0.5;
			data.offsetY -= height * scale * 0.5;
		},
		roomName(room, idx) {
			const text = room.name;
			let width = room.width * this.data[idx].scale;
			if (1 === text.length) return text;

			width = Math.floor(width) - 10;
			this.$refs.strWidth.innerText = text;
			let realWidth = this.$refs.strWidth.clientWidth;
			let pos = Math.floor((width / realWidth) * text.length);
			for (let cnt = 0; ++cnt < 10 && width <= realWidth; --pos) {
				this.$refs.strWidth.innerText = text.substr(0, pos) + '…';
				realWidth = this.$refs.strWidth.clientWidth;
			}

			return pos < text.length ? text.substr(0, pos < 1 ? 1 : pos - 1) + '…' : text;
		},
		lenOfStr(str) {
			this.$refs.strWidth.innerText = str;
			return this.$refs.strWidth.clientWidth;
		},
		enterContent(content) {
			let count = content.match(/\n/g);
			if (count != null && count.length > 4) {
				let tmp = '';
				for (let i = 0; i < 5; i++) {
					tmp += content.split('\n')[i] + '\n';
				}
				content = tmp.substring(0, tmp.length - 1);
			}
			return content;
		},
		setIntervalStr(intervalType) {
			return setIntervalStr(intervalType);
		},
		setDateFormat(schedule) {
			let date = schedule.beginDate;
			switch (schedule.intervalType) {
				case 'Y':
					date = this.$moment(date).format('M월 D일');
					break;
				case 'M':
					date = this.$moment(date).format('D일');
					break;
				case 'W':
					switch (this.$moment(date).day()) {
						case 0:
							date = '일요일';
							break;
						case 1:
							date = '월요일';
							break;
						case 2:
							date = '화요일';
							break;
						case 3:
							date = '수요일';
							break;
						case 4:
							date = '목요일';
							break;
						case 5:
							date = '금요일';
							break;
						case 6:
							date = '토요일';
							break;
					}
					break;
				case 'D':
					date = this.$moment(date).format('YYYY월 M월 D일');
					break;
			}
			return date;
		},
	},
};
</script>

<style scoped>
.locationCount {
	padding-left: 5px;
	background: none;
}
.viewArea {
	position: relative;
	height: 500px;
	overflow: hidden;
}
@media (max-width: 767px) {
	.viewArea {
		position: relative;
		height: 300px;
		overflow: hidden;
	}
}
</style>
