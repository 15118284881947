<template>
	<div :class="{ 'layerPop botPop': true, on: dialog }">
		<div class="inner" style="width:320px; position:absolute;">
			<div class="cont">
				<ul>
					<li v-if="-1 !== floor && 1 !== floor">
						<a href="javascript:void(0)" @click="OnSelectBase">아래층 도면 복사</a>
					</li>
					<li class="file">
						<input type="file" accept="*/*" id="selectFile" title="파일 선택" @change="onChangeFile" />파일 선택
					</li>
					<li class="file dNone">
						<input type="file" capture accept="image/*" title="사진촬영" @change="onChangeFile" />사진촬영
					</li>
				</ul>
			</div>
			<a href="javascript:void(0)" @click="onCancel">닫기</a>
		</div>
	</div>
</template>
<script>
import file from '@/api/file';
import { showSpinner, hideSpinner } from '@/utils/cmm.js';
export default {
	props: {
		value: {},
	},
	data() {
		return {
			dialog: false,
			floor: 0,
			popupResolve: null,
			data: [],
		};
	},
	methods: {
		async onChangeFile({ target }) {
			if (!(target.files && target.files.length)) return;
			const fileObj = target.files[0];

			if (fileObj.size > 1024 * 1024 * 10) {
				return await this.$alert('도면 파일 크기는 10MB를 초과할 수 없습니다.');
			}

			const filename = fileObj.name;
			let pos = filename.lastIndexOf('.');
			const ext = filename.substr(pos).toLowerCase();
			let res;
			switch (ext) {
				case '.pdf':
					showSpinner();
					try {
						res = await file.uploadPdf([fileObj]);
					} catch (ex) {
						return await this.$alert('서버에러가 발생했습니다. 관리자에게 문의해주세요.');
					} finally {
						document.querySelector('#selectFile').value = null;
						hideSpinner();
					}
					break;
				case '.png':
				case '.jpg':
				case '.jpeg':
				case '.webp':
				case '.jfif':
					showSpinner();
					try {
						res = await file.upload([fileObj]);
					} catch (ex) {
						return await this.$alert('서버에러가 발생했습니다. 관리자에게 문의해주세요.');
					} finally {
						document.querySelector('#selectFile').value = null;
						hideSpinner();
					}
					break;
				default:
					document.querySelector('#selectFile').value = null;
					return await this.$alert(`${ext ? ext.substr(1) + '는' : ''}지원하지 않은 포멧입니다.`);
			}
			this.onClose({ type: 'file', value: res.data[0] });
		},
		onCancel() {
			this.onClose(null);
		},
		onClose(value) {
			this.dialog = false;
			this.data = [];
			this.floor = 0;
			if (this.popupResolve) {
				this.popupResolve(value);
				this.popupResolve = null;
			}
		},
		OnSelectBase() {
			this.onClose({ type: 'copy' });
		},
		select(floor) {
			if (this.popupResolve) return null;
			this.dialog = true;
			this.floor = floor;
			return new Promise(resolve => (this.popupResolve = resolve));
		},
	},
};
</script>

<style scoped></style>
