<template>
	<!-- 팝업 -->
	<div class="layerPop alert on" v-if="dialog">
		<div class="inner" style="width:320px">
			<strong class="tit">오브제핀 안내</strong>
			<div class="cont">
				<div class="inputTable">
					<table>
						<colgroup>
							<col style="width:80px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row">오브제명</th>
								<td>
									<input type="text" v-model="data.name" disabled />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- btnArea -->
			<div class="btnArea">
				<button type="button" class="btn btnGray" @click="onClose()">
					취소
				</button>
				<button type="button" class="btn" @click="onCreateMemo">메모생성</button>
			</div>
			<!--// btnArea -->
		</div>
	</div>
	<!-- 팝업 -->
</template>
<script>
export default {
	data() {
		return {
			dialog: false,
			popupResolve: null,
			data: { name: '', roomId: null, id: null },
		};
	},
	methods: {
		async onSave() {
			let res = await this.validate();
			if (!res) return;
			this.onClose(this.data);
		},
		onClose(value) {
			this.dialog = false;
			this.data = { name: '', roomId: null, id: null };

			if (this.popupResolve) {
				this.popupResolve(value);
				this.popupResolve = null;
			}
		},
		select(value) {
			if (this.popupResolve) {
				return null;
			}

			if (value) {
				this.data.name = value.name;
				this.data.roomId = value.roomId;
				this.data.id = value.id;
			}

			this.dialog = true;
			return new Promise(resolve => (this.popupResolve = resolve));
		},
		onCreateMemo() {
			this.$emit('createMemo', Object.assign({}, this.data));
			this.onClose();
		},
	},
};
</script>

<style scoped></style>
