// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/friend';

async function get(state) {
	let params = {
		state: state,
	};
	return axiosApi.get(`${urlPath}`, { params });
}

async function friendReq() {
	return axiosApi.get(`${urlPath}/friendReq`);
}

async function getOne(params) {
	return axiosApi.get(`${urlPath}/info`, { params });
}

async function insert(buildingId, authority, friendKey, mobileNo, loginId) {
	let params = {
		buildingId: buildingId,
		authority: authority,
		friendKey: friendKey,
		mobileNo: mobileNo,
		loginId: loginId,
		state: 'S',
	};
	return axiosApi.post(`${urlPath}`, params);
}

async function acceptFriend(userId, friendId, buildingId) {
	let params = {
		userId: userId,
		friendId: friendId,
		buildingId: buildingId,
		state: 'F',
	};
	return axiosApi.put(`${urlPath}`, params);
}
async function remove(userId, friendId, buildingId) {
	let params = {
		userId: userId,
		friendId: friendId,
		buildingId: buildingId,
	};
	return axiosApi.delete(`${urlPath}`, { params });
}

export default { get, friendReq, getOne, acceptFriend, remove, insert };
