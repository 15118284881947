// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/objectpins';

async function get(params) {
	params = params || {};
	params.t = Date.now();
	return axiosApi.get(`${urlPath}`, { params });
}

function getOne(id) {
	const params = { t: Date.now() };
	return axiosApi.get(`${urlPath}/${id}`, { params });
}

function create(req) {
	return axiosApi.post(`${urlPath}`, req);
}

function update(req) {
	return axiosApi.put(`${urlPath}`, req);
}

function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}

export default { get, getOne, create, update, remove };
